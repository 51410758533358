import { OverlayProvider } from "@toss/use-overlay";
import { ThemeProvider } from "@emotion/react";
import type { AppProps } from "next/app";
import { RecoilRoot } from "recoil";
import "@/styles/reset.css";
import "@/styles/theme/font.css";

import { ChakraProvider } from "@chakra-ui/react";
import { getUserId, HackleProvider } from "@hackler/react-sdk";

import AOS from "aos";
import "aos/dist/aos.css";
import {
  chakraTheme,
  getUserProperties,
  initUserProperties,
  theme,
  HackleClient,
} from "goi_common";

import Script from "next/script";
import { ReactElement, ReactNode, useEffect, useState } from "react";
import { NextPage } from "next";
import { Hydrate, QueryClient, QueryClientProvider } from "react-query";

import "slick-carousel/slick/slick.css";
import Layout from "@/components/Layout";
import Head from "next/head";
import { naverPageView } from "@/utils/naverPageView";
import { Router } from "next/router";
import { GlobalStyle } from "@/styles/GlobalStyle";
import { ReactQueryDevtools } from "react-query/devtools";

declare global {
  interface Window {
    kakao: any;
    naver: any;
    hackleClinet: any;
    eziok_std_process: (url: string, type: string, callback: string) => void;
    TossCert: any;
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  },
});

// eslint-disable-next-line @typescript-eslint/ban-types
export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const googleTagManagerScript = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM}');
    `;

  const facebookPixelScript = `
    !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '1159415875062121');
    fbq('track', 'PageView');
  `;

  const handleResize = () => {
    const vh = window.innerHeight * 0.01;

    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  useEffect(() => {
    AOS.init();
    const goiMemberId = localStorage.getItem("goi-member-id");

    if (goiMemberId) setMemberId(goiMemberId);
  }, []);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [memberId, setMemberId] = useState("");

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      naverPageView();
    };
    Router.events.on("routeChangeComplete", handleRouteChange);

    return () => Router.events.off("routeChangeComplete", handleRouteChange);
  }, []);

  useEffect(() => {
    if (HackleClient) {
      initUserProperties();
      let id = getUserId();

      if (memberId) {
        id = memberId;
      }

      const user = {
        userId: id,
        properties: getUserProperties(),
      };

      HackleClient.setUser(user);
    }
  }, [memberId]);

  const Components = (
    <HackleProvider hackleClient={HackleClient} supportSSR>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <ChakraProvider resetCSS={true} theme={chakraTheme}>
          <QueryClientProvider client={queryClient}>
            <Hydrate state={pageProps.dehydratedState}>
              <RecoilRoot>
                <OverlayProvider>
                  <Layout>
                    <Component {...pageProps} />
                  </Layout>
                </OverlayProvider>
              </RecoilRoot>
              <ReactQueryDevtools />
            </Hydrate>
          </QueryClientProvider>
        </ChakraProvider>
      </ThemeProvider>
    </HackleProvider>
  );

  return (
    <>
      <Head>
        <title>
          {pageProps.pageOgTitle
            ? pageProps.pageOgTitle
            : "똑똑한 소비자를 위한 100원 상조 | 고이 프라임"}
        </title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Head>
      <header>
        {process.env.NEXT_PUBLIC_IS_PRODUCTION === "true" && (
          <>
            <Script
              id="gtm"
              strategy="afterInteractive"
              dangerouslySetInnerHTML={{ __html: googleTagManagerScript }}
            />
            <Script
              src="//wcs.naver.net/wcslog.js"
              onLoad={() => naverPageView()}
            />
            <Script
              id="fb-pixel"
              dangerouslySetInnerHTML={{ __html: facebookPixelScript }}
            />
            <Script
              type="text/javascript"
              charSet="UTF-8"
              src="//t1.daumcdn.net/kas/static/kp.js"
            />
          </>
        )}
        <Script
          id="eziok"
          src={`https://${process.env.NEXT_PUBLIC_EZI_CERT_URL}/stdauth/ds_auth_ptb/asset/js/ptb_ezauth_proc.js`}
          dangerouslySetInnerHTML={{
            __html: "window.eziok_std_process = eziok_std_process",
          }}
        />
        <Script id="toss-cert" src="https://cdn.toss.im/cert/v1" />
      </header>
      {Components}
    </>
  );
}

export default MyApp;
